import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

export default class seo extends Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={data => {
          let { title, description, keywords, slug } = this.props
          const { site } = data
          const {
            titleTemplate,
            defaultTitle,
            defaultDescription,
            twitter,
            author,
            year,
            siteUrl,
          } = site.siteMetadata
          title = title || defaultTitle
          let cleanText =
            description === undefined
              ? ''
              : description.replace(/(<([^>]+)>)/gi, '')
          description =
            cleanText.substr(0, cleanText.indexOf('.')) || defaultDescription
          keywords = keywords || 'HTML,CSS,XML,JavaScript'
          slug = slug !== undefined ? siteUrl + slug : siteUrl
          return (
            <Helmet
              htmlAttributes={{
                lang: 'en',
              }}
              title={title}
              titleTemplate={titleTemplate}
            >
              <meta name="description" content={description} />
              <meta name="keywords" content={keywords} />
              <meta name="author" content={author} />
              <meta name="copyright" content={`${title} - ${year}`} />
              <meta name="url" content={slug} />
              {twitter && <meta name="twitter:creator" content={twitter} />}
            </Helmet>
          )
        }}
      />
    )
  }
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        author
        year
      }
    }
  }
`
